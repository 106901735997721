import React from 'react'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import { clickEvent, conversionEvent, getLocation } from '../../utils/events'
import { OutboundLink } from "gatsby-plugin-google-gtag"

import styles from './Contact.module.css'

const Contact = ({ backgroundColor, header }) => (
  <Container
    id="contact"
    style={{ backgroundColor: backgroundColor || "#FFFFFF" }}
    className={styles.containerStyling}
  >
    <Row>
      <Col className={styles.contactSection}>
        <h2 className={styles.sectionHeader}>{header || "Leave us a note"}</h2>
        <Form
          action="/contact-success"
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <Form.Group className={styles.labels} as={Row}>
            <Form.Label column sm={12}>
              Name*
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                type="text"
                id="first_name"
                placeholder="First"
                name="first_name"
              />
            </Col>
            <Col sm={6}>
              <Form.Control
                type="text"
                id="last_name"
                placeholder="Last"
                name="last_name"
              />
            </Col>
          </Form.Group>
          <Form.Group className={styles.labels}>
            <Form.Label>Email address*</Form.Label>
            <Form.Control
              type="email"
              id="email"
              placeholder="Enter email"
              name="email"
              required
            />
          </Form.Group>
          <Form.Group style={{ textAlign: "left" }}>
            <Form.Label style={{ display: "block" }}>
              What are you interested in hearing about?
            </Form.Label>
            <Form.Check
              inline
              label="Plaid"
              type="checkbox"
              id="usePlaid"
              name="usePlaid"
            />
            <Form.Check
              inline
              label="Canvas"
              type="checkbox"
              id="useCanvas"
              name="useCanvas"
            />
            <Form.Check
              inline
              label="IEEE 2030.5"
              type="checkbox"
              id="IEEE-20305"
              name="IEEE-20305"
            />
            <Form.Check
              inline
              label="Other / Misc."
              type="checkbox"
              id="otherInterest"
              name="otherInterest"
            />
          </Form.Group>
          <Form.Group className={styles.labels}>
            <Form.Label>Anything else?</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              id="message"
              name="message"
              placeholder="Any details and questions about what you are working on are helpful!"
            />
          </Form.Group>
          <Form.Group style={{ textAlign: "left" }}>
            <Form.Label style={{ display: "block" }}>
              We have a quarterly(ish) newsletter with industry & protocol news,
              commentary and product updates, would you like us to add you?
            </Form.Label>
            <Form.Check
              inline
              label="Yes, add me"
              type="checkbox"
              id="signupNewsletter"
              name="signupNewsletter"
            />
          </Form.Group>
          <Button
            className="btn-gridfabric"
            type="submit"
            size="lg"
            onClick={() => {
              clickEvent("ContactForm", "Submit", getLocation())
              conversionEvent("AW-690244398/n3m0CNj8xtABEK6WkckC")
            }}
          >
            Demand Response
          </Button>
        </Form>
      </Col>
    </Row>
  </Container>
)

export default Contact