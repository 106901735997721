import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Contact from "../components/Contact/Contact"

const AboutPage = props => (
  <Layout>
    <SEO title="About" />
    <div>
      <div className="page-header">
        <Container>
          <h1>About</h1>
        </Container>
      </div>
    </div>
    <div className="divider-image">
      <Img fixed={props.data.icon.image.fixed} alt="" />
    </div>
    <div style={{ padding: "0 10%" }}>
      <Container style={{ maxWidth: "600px" }}>
        <h1 style={{ textAlign: "center", marginBottom: "50px" }}>
          Who we are
        </h1>
        <p>
          <strong style={{ color: "#31c856", fontSize: "1.1rem" }}>
            We are committed to building quality software in service of the
            clean energy transition.
          </strong>
        </p>
        <p>
          We help harmonize grid
          operations and integrate more renewable generation by implementing
          communication protocols for companies that operate and optimize the
          power grid and products that consume energy.
        </p>
        <p>
          GridFabric's founders (formerly{" "}
          <OutboundLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.nebland.com/"
          >
            nebland software
          </OutboundLink>
          ) created the original open source <strong>OpenADR VEN</strong> and{" "}
          <strong>VTN</strong> software released by EPRI and has participated in
          its development since then. We have facilitated dozens of integrations
          with OpenADR and other load shifting standards. 
        </p>
        <p>
          We are dedicated to what we do and are excited to share our expertise
          and collaborate on new developments.
        </p>
      </Container>
    </div>
    <Row>
      <Col>
        <div className="divider-image">
          <Img
            fluid={props.data.dividerImage.image.fluid}
            alt=""
          />
        </div>
      </Col>
    </Row>
    <Contact header="Get In Touch" />
  </Layout>
)

export default AboutPage

export const aboutPageQuery = graphql`
         query {
           icon: contentfulWebsiteImages(
             imageId: { eq: "gridfabricMiniIcon" }
           ) {
             image {
               fixed(width: 30) {
                 ...GatsbyContentfulFixed_withWebp
               }
             }
           }
           dividerImage: contentfulWebsiteImages(
             imageId: { eq: "contactGraphic" }
           ) {
             image {
               fluid {
                 ...GatsbyContentfulFluid
               }
             }
           }
         }
       `